<template>
  <div class="classroom">

    <!-- 课室列表 -->
    <div class="classroom-list">
      <div class="classroom-title">
        课室列表
      </div>
      <van-collapse v-model="classroomBuildingId"
                    accordion>
        <van-collapse-item v-for="item in buildData"
                           :key="item.id"
                           :title="item.name"
                           :name="item.id">
          <div class="collspse-content">
            <div class="content-line"
                 v-for="i in item.classrooms"
                 :class="[classroomId==i.id? 'select' : '']"
                 :key="i.id"
                 @click="getClassroomId(i.id)">
              <span>{{i.name}} </span>
            </div>
            <div class="add-line"
                 @click="addClassroom">新增课室</div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>

    <!-- 基础信息 -->
    <div class="classroom-info">
      <div class="classroom-title">
        基础信息
      </div>
      <van-form @submit="onSubmit"
                :disabled="isClick">
        <van-field v-model="classroomForm.name"
                   name="课室名称"
                   label="课室名称"
                   placeholder="请填写"
                   required
                   :rules="[{  required: true, message: '请填写不能为空' }]" />
        <!-- <van-field v-model="classroomForm.classroomBuildingId"
                   name="所在楼栋"
                   label="所在楼栋"
                   placeholder="请选择"
                   required
                   :rules="[{ required: true, message: '所在楼栋不能为空' }]">
          <template #input>
            <div @click="vanPopupClick('classroomBuildingId')">
              <div v-if="!classroomForm.classroomBuildingId"
                   class="content-disabled">请选择</div>
              <div v-else>{{getBuildName(classroomForm.classroomBuildingId)}}</div>
            </div>
          </template>
        </van-field> -->
        <van-field v-model="classroomForm.acreage"
                   name="课室面积"
                   label="课室面积"
                   placeholder="请填写" />
        <van-field v-model="classroomForm.capacity"
                   name="容纳人数"
                   label="容纳人数"
                   placeholder="请填写" />
        <van-field v-model="classroomForm.remark"
                   name="课室说明"
                   label="课室说明"
                   placeholder="请填写" />

        <van-field name="图片"
                   label="图片">
          <template #input>
            <van-uploader v-model="fileListImage"
                          :before-delete="(res,row)=>{return uploadRemove(res,row.index)}"
                          multiple />
          </template>
        </van-field>

        <van-field name="合同文件"
                   label="合同文件">
          <template #input>
            <div class="content">
              <van-uploader accept=".doc,.docx,.pdf,.jpg,.png,.xls"
                            :after-read='addCommodity'
                            :deletable="false">
                <van-button icon="plus"
                            type="info"
                            size="small">上传附件</van-button>
              </van-uploader>
              <p>支持扩展名:.doc .docx .pdf .jpg...</p>
              <div class="content-line"
                   v-for="(item,index) in fileList.filter(val=>{return val.type=='file'})">
                <div>
                  {{item.name}}
                </div>

                <div>
                  <van-icon name="delete-o"
                            class="line-deldete"
                            @click="uploadRemove(item,index)" />
                  <a :href="item.url"
                     :download="item.name">下载 </a>
                </div>
              </div>
            </div>
          </template>
        </van-field>

        <van-field name="课室设备"
                   label="课室设备">
          <template #input>
            <div class="content">
              <div class="text-button"
                   @click="dialogConfirm">添加设备</div>
              <div class="content-line"
                   :key="index"
                   v-for="(item,index) in classroomForm.facilitys">
                <div>
                  <span>{{item.name}}</span>
                  x
                  <span>{{item.number}}</span>
                </div>
                <div class="delete"
                     @click="deleFacility(index)">删除</div>
              </div>
            </div>
          </template>
        </van-field>
        <van-field name="借出单位基本要求"
                   label="借出单位基本要求"
                   type="textarea"
                   placeholder="请输入借出单位基本要求"
                   :autosize="{minHeight: 50}"
                   v-model="classroomForm.basicRequirements">
        </van-field>
        <van-field name="借出单位办公室意见"
                   label="借出单位办公室意见"
                   placeholder="请输入借出单位办公室意见"
                   type="textarea"
                   :autosize="{minHeight: 50}"
                   v-model="classroomForm.officeOpinion">
        </van-field>
        <van-field name="备注"
                   label="备注"
                   placeholder="请输入备注"
                   type="textarea"
                   :autosize="{minHeight: 50}"
                   v-model="classroomForm.note">
        </van-field>
        <van-field name="时段设置"
                   label="时段设置">
          <template #input>
            <div class="text-button"
                 @click="vanPopupClick('date')">直用模板</div>
          </template>
        </van-field>

        <van-field name="资产关联"
                   label="资产关联">
          <template #input>
            <!-- dialogAssets -->
            <div class="content">
              <div class="text-button"
                   @click="dialogAssetsBtn">添加关联</div>
              <van-collapse v-model="assetsDataId"
                            accordion>
                <van-collapse-item v-for="item in assetsData"
                                   :key="item.id"
                                   :title="item.name"
                                   :name="item.id">
                  <div class="collspse-content">

                    <div class="content-line">
                      <span>型号规格</span>
                      <span>{{item.specifications}}</span>
                    </div>
                    <div class="content-line">
                      <span>位置</span>
                      <span>{{item.storagePlace}}</span>
                    </div>
                    <div class="content-line">
                      <span>操作</span>
                      <span class="delete"
                            @click="assetsDelete(item)">删除</span>
                    </div>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </div>
          </template>
        </van-field>

        <div class="checkbox">
          <van-checkbox class="checkbox-line"
                        v-model="classroomForm.date.closedOnWeekends"
                        shape="square">周末不开放</van-checkbox>
          <van-checkbox class="checkbox-line"
                        v-model="classroomForm.date.closedOnHolidays"
                        shape="square">节假日不开放</van-checkbox>
        </div>
        <van-calendar :show-title='false'
                      :poppable="false"
                      :show-confirm="false"
                      :formatter="formatter"
                      :default-date='defaultDate'
                      color="#CFCFCF"
                      type='multiple'
                      @select="onSelectCalendar"
                      :style="{ height: '500px' }" />

        <van-button round
                    block
                    :disabled="isClick"
                    type="info"
                    native-type="submit">提交</van-button>
      </van-form>
    </div>

    <!-- 添加设备弹框 -->
    <van-dialog v-model="showDialog"
                title="课室设备"
                :beforeClose="beforeClose"
                show-cancel-button>
      <div class="content">
        <!-- 设备名称 -->
        <van-form ref="vanForm">
          <van-field v-model="dialogInfo.name"
                     label="设备名称"
                     placeholder="请输入"
                     required
                     :rules="[{ required: true, message: '请选择' }]">
            <template #input>
              <div v-if="!dialogInfo.name"
                   class="content-disabled"
                   @click="vanPopupClick('name')">请选择</div>
              <div v-else
                   @click="vanPopupClick('name')">{{dialogInfo.name}}</div>
            </template>
          </van-field>
          <van-field v-model="dialogInfo.number"
                     label="数量"
                     type="digit"
                     placeholder="请输入"
                     required
                     :rules="[{ required: true, message: '请输入' }]" />
        </van-form>
      </div>
    </van-dialog>

    <!-- 资产关联 -->
    <van-dialog v-model="dialogAssets"
                @confirm="dialogAssettsBtn"
                show-cancel-button>
      <div class="header">
        <div class="search">
          <van-search class="search-content"
                      v-model.trim="screen.name"
                      @search="onSearch"
                      placeholder="请输入搜索关键词" />
          <div class="search-btn"
               @click="search">搜索</div>
        </div>
        <div class="filter">
          <div class="filter-item"
               @click="pickerBtn('assetCategory')">
            <span>{{ filter.status.label }}</span>
            <van-icon class="filter-down"
                      name="arrow-down" />
          </div>
        </div>
      </div>
      <div class="dialog-content">
        <van-list v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  finished-text="没有更多了"
                  @load="onLoad">
          <van-collapse v-model="collapse"
                        accordion>
            <van-checkbox-group v-model="selectList">
              <template v-for="item in assetsList">
                <van-checkbox class="dialog-checkbox"
                              label-disabled
                              :key="item.id"
                              :disabled="disabledList.includes(item.id)"
                              :name="item.id">
                  <van-collapse-item :key="item.id"
                                     :title="item.name"
                                     :name="item.id">
                    <div class="content">
                      <div class="content-line">
                        <span>型号规格</span>
                        <span>{{item.specifications}}</span>
                      </div>
                      <div class="content-line">
                        <span>位置</span>
                        <span>{{item.storagePlace}}</span>
                      </div>
                      <div class="content-line">
                        <span>操作</span>
                        <span class="delete"
                              @click="assetsDelete(item)">删除</span>
                      </div>
                    </div>
                  </van-collapse-item>
                </van-checkbox>
              </template>
            </van-checkbox-group>
          </van-collapse>
        </van-list>
      </div>
    </van-dialog>
    <!-- 选择弹框 -->
    <van-popup v-model="showPopup"
               round
               position="bottom">
      <custom-popup :title="pickerTitle"
                    :columns="pickerColumns"
                    @cancel="showPopup = false"
                    @confirm="pickerConfirm" />
    </van-popup>
    <!-- 选择弹框 -->
    <van-popup v-model="showVanPopupType"
               round
               position="bottom">

      <custom-popup title="课室设备"
                    :columns="vanPopupTypeColumns"
                    swipe-duration="500"
                    value-key="name"
                    @cancel="vanPopupCancel"
                    @confirm="vanPopupConfirm"></custom-popup>
    </van-popup>

  </div>
</template>

<script>
import "@/assets/less/views/classroom/classroom.less";
import { SUCCESS_CODE } from "@/apis/notification";
import Filter from "ldap-filters";

export default {
  name: "classroom",
  data() {
    return {
      classroomBuildingId: "",
      assetsDataId: "",
      classroomId: "",
      classroomForm: {
        date: {
          date: [],
          closedOnWeekends: false, //周末不开放
          closedOnHolidays: true //节假日不开放
        }, //日期
        facilitys: [
          // {
          //   name: null,
          //   number: 1,
          //   classroomId: null
          // }
        ]
      },
      dialogInfo: {},
      checked: false,
      showDialog: false,
      buildData: [],
      equipmentList: [],
      vanPopupTypeColumns: [],
      fileList: [],
      showVanPopupType: false,
      defaultDate: null,
      isClick: false,
      assetsList: [],
      assetsData: [],
      dialogAssets: false,
      screen: {
        status: "ENABLE",
        name: "",
        assetCategory: "",
        type: "OTHER"
      },
      listStatus: {
        loading: true,
        finished: false
      },
      pager: {
        pageNumber: 1,
        pageSize: 10,
        totalRecords: 0
      },
      collapse: "",
      pickerTitle: "",
      pickerColumns: [],
      selectList: [],
      disabledList: [],
      showPopup: false,
      filter: {
        status: {
          id: "",
          label: "资产分类"
        },
        title: "资产分类"
      }
    };
  },
  computed: {
    fileListImage: {
      get: function () {
        return this.fileList.filter(val => {
          return val.type == "image";
        });
      },
      set: function (value) {
        this.fileList = value.map(val => {
          if (val.content) {
            return {
              content: val.content,
              file: val.file,

              suffix: val.file.name.split(".")[val.file.name.split(".").length - 1],
              name: val.file.name,
              type: "image"
            };
          } else {
            return val;
          }
        });
      }
    }
  },
  methods: {
    init() {
      this.getClassroomBuildings();
      this.getDictsList();
      this.getClassroomDisableDateTemplates();
      this.isClick = false;
      this.equipmentIds = [];
      this.urlDelete = [];
      this.fileList = [];
      this.assetsList = [];
      this.assetsData = [];
    },
    async getClassroomBuildings() {
      let res = await this.$api.getClassroomBuildings();
      this.buildData = res.data;
    },
    async getDictsList() {
      let ret = await this.$api.getDictsList();
      this.equipmentList = ret.data["设备"][0].dictOptions;
      this.assetsTypeList = ret.data["资产分类"][0].dictOptions;
    },
    async getClassroomId(id) {
      this.init();
      this.classroomId = id;
      let ret = await this.$api.getClassroomDetail({
        placeholder: { id },
        params: {
          useType: "MANAGER"
        }
      });
      this.classroomForm = ret.data;
      if (this.classroomForm.date && this.classroomForm.date.date) {
        this.defaultDate = this.classroomForm.date.date.map(val => {
          return new Date(val);
        });
      }
      if (this.classroomForm.id) {
        let ret = await this.$api.getAssets({
          params: {
            page: false,
            query: "(classroomId=" + this.classroomForm.id + ")"
          }
        });
        this.assetsData = ret.data;
        this.disabledList = ret.data.map(val => {
          return val.id;
        });
        this.selectList = ret.data.map(val => {
          return val.id;
        });
      }
      this.fileListCopy = ret.data.attachments;
      let regex = /\[(.+?)\]/g; // 中括号
      let name;
      this.fileList = await Promise.all(
        ret.data.attachments.map(async (val, ind) => {
          try {
            name = val.attachmentUrl.match(regex)[0].replace("[", "").replace("]", "");
          } catch (error) {
            name = ind + "." + val.suffix;
          }
          return {
            name,
            url: await this.getAppendixes(val),
            id: val.id,
            type: val.type
          };
        })
      );
    },
    async getAppendixes(par) {
      let ret = await this.$api.getAppendixFilePath({ params: { urls: par.attachmentUrl } });
      return ret.data[0];
    },
    async getClassroomDisableDateTemplates() {
      let ret = await this.$api.getClassroomDisableDateTemplates();
      this.tempData = ret.data;
    },
    //新增课室
    async postClassrooms(params) {
      let ret = await this.$api.addClassroom({ params });
      if (ret.code != SUCCESS_CODE) return;
      if (this.assetsData.length) {
        this.assetsData.forEach(val => {
          let assetsId = val.id;
          this.putAssetsId({ classroomId: ret.data.id }, assetsId);
        });
      }
      this.$utils.success("新增成功");
      setTimeout(() => {
        this.$router.go(-1);
      }, 1500);
    },
    //编辑课室
    async putClassroomId(params, id) {
      let ret = await this.$api.putClassroomId({ params, placeholder: { id } });
      if (ret.code != SUCCESS_CODE) return;
      this.$utils.success("编辑成功");
      setTimeout(() => {
        this.$router.go(-1);
      }, 1500);
    },
    // 删除课室设备
    async deleteClassroomsIdFacilitys() {
      if (this.equipmentIds.length == 0) return false;
      let ret = await this.$api.deleteClassroomsIdFacilitys({
        placeholder: { id: this.classroomId, ids: this.equipmentIds.join(",") },
        params: {
          ids: this.equipmentIds.join(",")
        }
      });
      if (ret.code != SUCCESS_CODE) return;
    },
    deleFacility(index) {
      this.classroomForm.facilitys = this.classroomForm.facilitys.filter((val, ind) => {
        if (ind == index) {
          if (val.id) this.equipmentIds.push(val.id);
          return false;
        }
        return true;
      });
    },
    addClassroom() {
      this.classroomForm = {
        date: {
          date: [],
          closedOnWeekends: false, //周末不开放
          closedOnHolidays: true //节假日不开放
        }, //日期
        facilitys: []
      };
      this.classroomId = "";
      this.init();
    },
    formatter(day) {
      // const month = day.date.getMonth() + 1;
      // const date = day.date.getDate();
      return day;
    },
    vanPopupClick(type) {
      if (this.isClick) return false;
      this.showVanPopupType = true;
      if (type == "name") {
        let arr = this.classroomForm.facilitys.map(val => {
          return val.name;
        });
        this.vanPopupTypeColumns = this.equipmentList.map(val => {
          let disabled = false;
          disabled = arr.includes(val.value);
          return { id: val.id, name: val.value, key: "name", disabled };
        });
      }
      if (type == "classroomBuildingId") {
        this.vanPopupTypeColumns = this.buildData.map(val => {
          return { id: val.id, name: val.name, key: "classroomBuildingId" };
        });
      }
      if (type == "date") {
        this.vanPopupTypeColumns = this.tempData.map(val => {
          return { id: val.id, name: val.name, key: "date", date: val.date, closedOnHolidays: val.closedOnHolidays, closedOnWeekends: val.closedOnWeekends };
        });
      }
    },
    async getFile() {
      let formData = new FormData();
      let ind = 0;
      this.fileList.forEach(val => {
        if (val.file) {
          formData.append(`files[${ind}]`, val.file);
          ind++;
        }
      });
      if (ind) {
        formData.append("type", "CLASSROOM");
        let ret = await this.postAppendixFilePath(formData);
        return this.fileList
          .filter(val => {
            return !val.id;
          })
          .map((val, index) => {
            return {
              assetId: "",
              attachmentUrl: ret.data[index],
              status: "ENABLE",
              type: val.type,
              suffix: val.file.name.split(".")[val.file.name.split(".").length - 1]
            };
          });
      } else {
        return [];
      }
    },
    // 图片上传
    postAppendixFilePath(files) {
      let ret = this.$api.postAppendixFilePath({ params: files });
      return ret;
    },
    async onSubmit() {
      if (this.isClick) return false;
      this.isClick = true;
      let params = {
        name: this.classroomForm.name, //课室名字
        classroomBuildingId: this.classroomForm.classroomBuildingId, //所在楼栋
        doorplateNumber: this.classroomForm.doorplateNumber, //门牌号码
        acreage: this.classroomForm.acreage, //面积
        capacity: this.classroomForm.capacity, //容纳人数
        remark: this.classroomForm.remark, //说明
        date: this.classroomForm.date,
        attachments: [], //	附件信息
        facilitys: this.classroomForm.facilitys,
        note: this.classroomForm.note,
        officeOpinion: this.classroomForm.officeOpinion,
        basicRequirements: this.classroomForm.basicRequirements
      };
      if (this.fileList.length) {
        params.attachments = await this.getFile();
      }
      if (this.classroomForm.id) {
        params.status = this.classroomForm.status;
        this.putClassroomId(params, this.classroomForm.id);
        if (this.assetsData.length) {
          this.assetsData.forEach(val => {
            let assetsId = val.id;
            this.putAssetsId({ classroomId: this.classroomForm.id }, assetsId);
          });
        }
        this.deleteClassroomsIdFacilitys();
        if (this.urlDelete.length) {
          this.urlDelete.forEach(val => {
            this.deleteAppendixes(val.url);
            this.deleteClassroomsAttachments(val.id, val.attachmentId);
          });
        }
        return false;
      }
      this.postClassrooms(params);
    },

    vanPopupCancel() {
      this.showVanPopupType = false;
    },
    vanPopupConfirm(val) {
      this.showVanPopupType = false;
      if (val.key == "classroomBuildingId") {
        this.$set(this.classroomForm, val.key, val.id);
      }
      if (val.key == "name") {
        this.$set(this.dialogInfo, val.key, val.name);
      }
      if (val.key == "date") {
        this.classroomForm.date.date = val.date;
        this.defaultDate = val.date.map(val => {
          return new Date(val);
        });
        this.classroomForm.date.closedOnHolidays = val.closedOnHolidays;
        this.classroomForm.date.closedOnWeekends = val.closedOnWeekends;
      }
    },
    uploadRemove(row, index) {
      this.fileList = this.fileList.filter((val, ind) => {
        return row.url != val.url;
      });
      if (row.id) {
        let find = this.fileListCopy.findIndex(val => val.id == row.id);
        this.urlDelete.push({
          url: this.fileListCopy[find].attachmentUrl,
          id: this.fileListCopy[find].classroomId,
          attachmentId: row.id
        });
        console.log(1234, this.urlDelete);
      }
    },
    // 图片删除
    async deleteAppendixes(url) {
      let ret = await this.$api.deleteAppendixes({ placeholder: { url } });
    },
    async deleteClassroomsAttachments(id, ids) {
      let ret = await this.$api.deleteClassroomsAttachments({ placeholder: { id, ids } });
    },
    async addCommodity(val) {
      console.log(1234, val);
      this.fileList.push({
        content: val.content,
        file: val.file,

        suffix: val.file.name.split(".")[val.file.name.split(".").length - 1],
        name: val.file.name,
        type: "file"
      });

      // let { file } = val;
      // let suffix = file.name.slice(file.name.lastIndexOf(".") + 1);
      // let type = ["jpg", "jpeg", "png", "jpe "].includes(suffix) ? "IMAGE" : "FILE";
      // let formData = new FormData();
      // formData.append("file", file);
      // formData.append("type", "DOCUMENT");
      // let ret = await this.postAppendixFilePath(formData);
      // this.fileList.push({ name: file.name });
      // this.fileListUrl.push({
      //   attachmentUrl: ret.data,
      //   status: "ENABLE",
      //   type,
      //   suffix
      // });
    },
    getBuildName(value) {
      let name;
      this.buildData.forEach(val => {
        if (val.id == value) name = val.name;
      });
      return name;
    },
    dialogConfirm() {
      if (this.isClick) return false;
      if (this.classroomForm && this.classroomForm.facilitys.length == this.equipmentList.length) return this.$utils.warning("暂无设备添加");
      this.showDialog = true;
      this.dialogInfo = {};
      if (this.$refs.vanForm) this.$refs.vanForm.resetValidation();
    },
    onSelectCalendar(params) {
      let arr = params.map(val => {
        return this.$moment(val).format("YYYY-MM-DD");
      });
    },
    async beforeClose(action, done) {
      if (action === "confirm") {
        this.$refs.vanForm.submit();
        if (this.dialogInfo.name && this.dialogInfo.number) {
          done();
          this.classroomForm.facilitys.push(this.dialogInfo);
        } else {
          done(false);
        }
      } else {
        done();
      }
    },
    assetsDelete(row) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定删除?"
        })
        .then(async () => {
          console.log(1234, row);
          if (row.id) {
            this.putAssetsId({ classroomId: 0 }, row.id);
          }
          this.assetsData = this.assetsData.filter(val => {
            console.log(1234, row.id, val.id);
            return row.id != val.id;
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    async getAssets(sign = false) {
      let params = {
        query: this.getQuery(),
        pageNumber: this.pager.pageNumber - 1
      };
      let ret = await this.$api.getAssets({
        params
      });
      if (sign) {
        this.assetsList = this.assetsList.concat(ret.data);
      } else {
        this.assetsList = ret.data;
      }
      this.pager.totalRecords = ret.totalRecords;
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    // 课室关联 资产
    async putAssetsId(params, id, type) {
      let ret = await this.$api.putAssetsId({ params, placeholder: { id: id } });
    },
    dialogAssetsBtn() {
      this.screen = {
        status: "ENABLE",
        name: "",
        assetCategory: "",
        type: "OTHER"
      };
      this.filter = {
        status: {
          id: "",
          label: "资产分类"
        },
        title: "资产分类"
      };
      this.pager.pageNumber = 1;
      this.getAssets();
      this.dialogAssets = true;
    },
    dialogAssettsBtn() {
      let ids = this.assetsData.map(val => {
        return val.id;
      });
      this.assetsData = this.assetsData.concat(
        this.assetsList.filter(val => {
          if (ids.includes(val.id)) return false;
          return this.selectList.includes(val.id);
        })
      );
      this.dialogAssets = false;
    },
    search() {
      this.pager.pageNumber = 1;
      this.getAssets();
    },
    handlePagination(totalPages) {
      if (this.pager.pageNumber < totalPages) {
        // 有更多数据
        this.pager.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    onSearch() {},
    onLoad() {
      this.getAssets(true);
    },
    pickerConfirm(val) {
      if (val.key == "assetCategory") {
        this.screen.assetCategory = val.id;
        this.filter.status.label = val.text;

        this.showPopup = false;
        this.getInitialData();
        return false;
      }
      this.borrowInfo[val.key] = val.id;
      this.showPopup = false;
    },
    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.pager.pageNumber = 1;
      this.getAssets();
    },
    pickerBtn(type, isClick = false) {
      console.log(1234, type);
      if (isClick) return;
      this.showPopup = true;

      if (type == "assetCategory") {
        this.filter.title = "资产分类";
        this.pickerColumns = [{ text: "全部分类", key: "assetCategory", id: "" }].concat(
          this.assetsTypeList.map(val => {
            return { text: val.value, key: "assetCategory", id: val.id };
          })
        );
      }
    },
    getQuery() {
      let filters = [];
      Object.keys(this.screen).forEach(key => {
        let filter = "equalTo";
        let value = this.screen[key];
        if (!this.screen[key]) return false;
        if (key == "name") filter = "contains";
        let query = Filter.attribute(key)[filter](value);
        filters.push(query);
      });
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    }
  },
  created() {
    this.init();
  }
};
</script>
